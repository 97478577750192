import Importer from "./Importer";
import Duplicates from "./Duplicates";

/**
 * Register global Vue components below and they will be auto-registered
 * with the root instance.
 */
export default {
    'pa-importer': Importer,
    'pa-duplicates': Duplicates,
};
