<template>

  <div id="pa-importer" class="pa-controls-body">

    <div class="pa-exporter-body">

      <h3>2. Choose Exporter</h3>

      <select :disabled="busy" class="pa-exporter-options" @change="onChange">

        <option value="none">No option</option>

        <optgroup label="Post Types" v-if="post_types.length > 0">
          <option v-for="(post_type, index) in post_types" :key="index" :value="post_type.slug"
                  data-type="post_type">{{ post_type.name.charAt(0).toUpperCase() + post_type.name.slice(1) }}
          </option>
        </optgroup>

        <optgroup label="Taxonomies" v-if="taxonomies.length > 0">
          <option v-for="(taxonomy, index) in taxonomies" :key="index" :value="taxonomy.slug" data-type="taxonomy">
            {{ taxonomy.name.charAt(0).toUpperCase() + taxonomy.name.slice(1) }}
          </option>
        </optgroup>

      </select>

      <div v-if="data_type !== 'none'" class="pa-exporter-options">
        <h3>3. Limit</h3>
        <p>Choose the maximum records to export. 0 - Unlimited</p>
        <input :disabled="busy" type="number" v-if="data_type !== 'none'" v-model="records_limit"
               placeholder="max # of records."/>
      </div>

      <div v-if="data_type !== 'none'" class="pa-exporter-options">
        <h3>4. Offset</h3>
        <p>Choose where to start exporting from. 0 - Beginning</p>
        <input :disabled="busy" type="number" v-if="data_type !== 'none'" v-model="records_offset"
               placeholder="(optional)"/>
      </div>

<!--      <div v-if="data_type !== 'none'" class="pa-exporter-panel">-->
<!--        <button type="button" :disabled="busy" class="button button-primary" :class={button__loading:showLoader} @click="runExport">-->
<!--          <span class="__button_text" :class={loading:showLoader}>-->
<!--             Export-->
<!--          </span>-->
<!--        </button>-->
<!--      </div>-->

      <div v-if="data_type !== 'none'" class="pa-importer-panel mt-2">
        <button type="button" :disabled="busy" class="button button-primary" :class={button__loading:showLoader} @click="runExport">
          <span class="__button_text" :class={loading:showLoader}>
             Export
          </span>
        </button>
      </div>

    </div>

<!--    <div class="loader-container">-->
<!--      <div v-if="showLoader" class="loader"></div>-->
<!--    </div>-->

    <div class="pa-importer-logs">
      <div class="error-container" v-if="errors.length > 0">
        <ul class="error-log">
          <li v-for="(log, index) in errors" :key="index" class="log-item">{{ log }}</li>
        </ul>
      </div>
      <div class="message-container" v-if="messages.length > 0">
        <ul class="message-log">
          <li v-for="(log, index) in messages" :key="index" class="log-item">{{ log }}</li>
        </ul>
      </div>
    </div>

  </div>

</template>

<script>
import axios from 'axios';

export default {
  mounted() {},
  props: {
    taxonomies: {
      type: Array,
      default: [],
    },
    post_types: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      data_type: 'none',
      records_limit: 0,
      records_offset: 0,
      showLoader: false,
      showProcessLoader: false,
      have_response: false,
      errors: [],
      messages: [],
      busy: false,
      value: '',
    }
  },
  methods: {
    onChange(e) {
      const target = e.currentTarget;
      const index = target.selectedIndex;
      this.data_type = target.options[index].dataset.type;
      this.export_value = target.options[index].value;
    },
    generateFilename() {
      let d = new Date();
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let day = d.getDate();
      let hour = d.getHours();
      let minute = d.getMinutes();
      let second = d.getSeconds();
      return `${year}-${month}-${day}-${hour}-${minute}-${second}-pa-product-${this.data_type}-export.csv`;
    },
    async runExport(e) {

      try {

        this.errors = [];
        this.messages = [];
        this.have_response = false;

        const url = window.pAImporterData.restAPIs.export;

        this.showLoader = true;
        this.busy = true;

        const response = await axios.post(url, {
          'data_type': this.data_type,
          'value': this.export_value,
          'limit': this.records_limit + '',
          'offset': this.records_offset + '',
        });

        this.showLoader = false;
        this.busy = false;

        console.log(response.data);
        this.messages.push(" ");

        // Handle successful export
        if (response.data.success) {
          // make response downloadable
          const blob = new Blob([response.data.exportData], {type: 'text/csv'});
          const url2 = window.URL.createObjectURL(blob);
          const link = document.createElement('a');

          const filename = this.generateFilename();

          link.setAttribute('href', url2);
          link.setAttribute('download', filename);
          link.click();
        } else {
          // Display errors
          if(response.data.errors.length > 0) {
            response.data.errors.forEach((err) => {
              this.errors.push(err);
            });
          }
        }

        // Display returned messages
        if(response.data.messages.length > 0) {
          response.data.messages.forEach((msg) => {
            this.messages.push(msg);
          });
        }

        this.have_response = true;
      } catch (err) {
        console.log(err);
      }
    },
  },
}
</script>

