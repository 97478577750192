<template>

  <div id="pa-importer" class="pa-controls-body">

    <div class="pa-exporter-body">

      <h3>PA Duplicate Products</h3>

      <div class="pa-duplicates-logs">
        <div class="duplicate-skus-container" v-if="duplicate_skus.length > 0">
          <h4>Duplicate Skus</h4>
          <ul class="message-log">
            <li v-for="(log, index) in duplicate_skus" :key="index" class="log-item" v-html="log"></li>
          </ul>
        </div>
        <div class="duplicate-titles-container" v-if="duplicate_titles.length > 0">
          <h4>Duplicate Titles</h4>
          <ul class="message-log">
            <li v-for="(log, index) in duplicate_titles" :key="index" class="log-item" v-html="log"></li>
          </ul>
        </div>
      </div>

      <div class="pa-importer-panel mt-2">
        <button type="button" :disabled="busy" class="button button-primary" :class={button__loading:showLoader} @click="runDuplicates">
          <span class="__button_text" :class={loading:showLoader}>
             Find Duplicates
          </span>
        </button>
      </div>

    </div>

    <div class="pa-importer-logs">
      <div class="error-container" v-if="errors.length > 0">
        <ul class="error-log">
          <li v-for="(log, index) in errors" :key="index" class="log-item">{{ log }}</li>
        </ul>
      </div>
      <div class="message-container" v-if="messages.length > 0">
        <ul class="message-log">
          <li v-for="(log, index) in messages" :key="index" class="log-item">{{ log }}</li>
        </ul>
      </div>
    </div>

  </div>

</template>

<script>
import axios from 'axios';

export default {
  mounted() {},
  props: {
    taxonomies: {
      type: Array,
      default: [],
    },
    post_types: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      showLoader: false,
      have_response: false,
      errors: [],
      messages: [],
      duplicate_skus: [],
      duplicate_titles: [],
      busy: false,
      value: '',
    }
  },
  methods: {
    async runDuplicates(e) {

      try {

        this.errors = [];
        this.messages = [];
        this.duplicate_skus = [];
        this.duplicate_titles = [];
        this.have_response = false;

        const url = window.pAImporterData.restAPIs.duplicates;

        this.showLoader = true;
        this.busy = true;

        const response = await axios.post(url);

        this.showLoader = false;
        this.busy = false;

        console.log(response.data);
        this.messages.push(" ");

        // Handle successful export
        if (response.data.success) {
          if(response.data.duplicates.skus.length > 0) {
            response.data.duplicates.skus.forEach((arr) => {
              let message = '';

              for(let i = 0; i < arr.length; i++) {
                message += '<a href="/wp/wp-admin/post.php?post=' + arr[i] + '&action=edit">' + arr[i] + '</a>';
                if(i + 1 < arr.length) {
                  message += ', ';
                }
              }

              this.duplicate_skus.push(message);
            });

            response.data.duplicates.titles.forEach((arr) => {
              let message = '';

              for(let i = 0; i < arr.length; i++) {
                message += '<a href="/wp/wp-admin/post.php?post=' + arr[i] + '&action=edit">' + arr[i] + '</a>';
                if(i + 1 < arr.length) {
                  message += ', ';
                }
              }

              this.duplicate_titles.push(message);
            });
          }
        } else {
          // Display errors
          if(response.data.errors.length > 0) {
            response.data.errors.forEach((err) => {
              this.errors.push(err);
            });
          }
        }

        // Display returned messages
        if(response.data.messages.length > 0) {
          response.data.messages.forEach((msg) => {
            this.messages.push(msg);
          });
        }

        this.have_response = true;
      } catch (err) {
        console.log(err);
      }
    },
  },
}
</script>

