<template>

  <div id="pa-importer-exporter" class="p-3">
    <div class="pa-importer-header">
      <h2 class="h2">{{ title }}</h2>
      <p>{{ description }}</p>
    </div>

    <div class="pa-controls-body">

      <h3>1. Choose Option</h3>
      <div class="select-container">
        <select class="pa-controls-options text-em-18" v-model="inputs.option" @change="changeState">
          <option value="none">No option</option>
          <option value="exporter">Export</option>
          <option value="importer">Import</option>
        </select>
        <div v-if="showLoader" class="loader -index-mmy"></div>
      </div>

    </div>

    <pa-exporter v-if="inputs.option === 'exporter'" :busy="busy" :post_types="post_types" :taxonomies="taxonomies" />
    <pa-importer v-if="inputs.option === 'importer'" :busy="busy" :post_types="post_types" :taxonomies="taxonomies" />

  </div>

</template>

<script>
import axios from 'axios';
import PaExporter from './PaExporter';
import PaImporter from "./PaImporter";

export default {
  props: {
    title: {
      type: String,
      default: 'PA Importer/Exporter',
    },
    description: {
      type: String,
      default: 'Upload a CSV file containing product data to import the contents into your shop.',
    },
  },
  components: {
    PaImporter,
    PaExporter,
  },
  async mounted() {
    console.log("Importer Button mounted!");
    await this.getDefaultOptions();
  },
  data() {
    return {
      showLoader: false,
      showProcessLoader: false,
      inputs: {
        option: 'none',
        post_type: 'none',
        export_value: 'none',
        records_limit: 0,
        records_offset: 0,
      },
      "exporter": {},
      states: {
        "importer": {
          'none': 'No importer',
          'posts': 'Posts',
          'products': 'Products',
          'terms': 'Terms',
        },
      },
      currentState: {
        'importer': 'none',
        'exporter': 'none'
      },
      allStates: {
        "importer": {
          "initial": function (instance) {
            instance.importType = 'none';
            instance.merge = 'none';
            instance.mergeKey = 'none';
          },
          "posts": function (instance) {
            instance.importType = 'posts';
            instance.merge = 'none';
            instance.mergeKey = 'none';
          },
          "products": function (instance) {
            instance.importType = 'products';
            instance.merge = 'none';
            instance.mergeKey = 'none';
          },
          "terms": function (instance) {
            instance.importType = 'terms';
            instance.merge = 'none';
            instance.mergeKey = 'none';
          },
        },
        "exporter": {
          "initial": function (instance) {
            instance.exportType = 'none';
            instance.merge = 'none';
            instance.mergeKey = 'none';
          },
          "posts": function (instance) {
            instance.exportType = 'posts';
            instance.merge = 'none';
            instance.mergeKey = 'none';
          },
          "products": function (instance) {
            instance.exportType = 'products';
            instance.merge = 'none';
            instance.mergeKey = 'none';
          },
          "terms": function (instance) {
            instance.exportType = 'terms';
            instance.merge = 'none';
            instance.mergeKey = 'none';
          },
        },

      },
      have_response: false,
      someData: '',
      file: '',
      errors: [],
      messages: [],
      merge: 'none',
      mergeKey: 'none',
      showProgressBar: false,
      completed: 0,
      total: 0,
      importType: 'none',
      exportType: 'none',
      progressBar: null,
      statusInterval: null,
      taxonomies: [],
      post_types: [],
      data_type: 'none',
    }
  },
  methods: {
    async getDefaultOptions() {
      try {

        this.errors = [];
        this.have_response = false;
        this.showLoader = true;
        const url = window.pAImporterData.restAPIs.importerOptions;
        const response = await axios.get(url);
        this.showLoader = false;

        if (response.data.success) {
          this.post_types = response.data.options.custom_post_types;
          this.states.importer = response.data.options.custom_post_types;
          if (response.data.options.taxonomies) {
            this.taxonomies = response.data.options.taxonomies;
          }
        } else {
          response.data.report.errors.forEach((err) => {
            this.errors.push(err);
          });
          response.data.report.messages.forEach((msg) => {
            this.messages.push(msg);
          });
        }

        this.have_response = true;

      } catch (err) {
        console.log(err);
      }
    },
    setDefaults() {
      this.importType = 'none';
      this.merge = 'none';
      this.mergeKey = 'none';
    },
    changeState(e) {
      const newState = e.currentTarget.value;
      this.merge = 'none';
      this.mergeKey = 'none';
      if (this.inputs.option !== 'none') {
        this.currentState[this.inputs.option] = newState;
      }
    },
  }
}
</script>

